#Feature1_0 .ant-row > .ant-col > .kh1vl89hij-editor_css {
  width: 200px;
}
#Feature1_0 .ant-row > .ant-col > .kh1vme0ulh-editor_css {
  line-height: 1.97;
}
#Content5_0 .home-page > div > .kh1vx8wt1eb-editor_css {
  align-items: center;
  justify-content: center;
  margin: 0px -8px 0;
  padding: 0 0;
}
#Content5_0 div > .ant-row > .kh1wcex5a3-editor_css {
  padding: 0 0;
}
#Feature6_0 div > .ant-row > .kh1wlvux4uo-editor_css {
  justify-content: center;
}
#Content9_0 .block-wrapper > .image-wrapper > .kh1wrvuoebr-editor_css {
  height: 28px;
}
#Feature6_0 .ant-row > .ant-col > .kh1xfk92o7p-editor_css {
  height: 28px;
}
#Nav3_1 .home-page > .kh1y7q9r23-editor_css {
  width: 100px;
}
#Teams0_0
  .banner-anim-elem
  > .teams0-content-wrapper
  > .kh1yt9xpxnj-editor_css {
  width: 130px;
  height: 130px;
  margin: auto auto 34px;
  padding: 0 0;
}
#Content5_0 .ant-col > .content5-block-content > .kh1vvvzjivc-editor_css {
  color: rgb(255, 255, 255);
  height: 120px;
  background-color: rgb(255, 255, 255);
}
#Content5_0 .ant-col > .content5-block-content > .kh23oe6jsqn-editor_css {
  margin: 30px 0px 0px;
}

@media screen and (max-width: 767px) {
  #Content5_0.kh25cpkd3g-editor_css {
    height: 100%;
  }
}
#Content5_0 .ant-row > .ant-col > .kh1wcmgabj-editor_css {
  padding: 20px 20px 60px;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-row > .ant-col > .kh1wcmgabj-editor_css {
    padding: 20px 20px 60px;
  }
}
#Content5_0 .ant-row > .ant-col > .kh1wakf4add-editor_css {
  float: left;
  padding: 20px 20px 60px;
}

@media screen and (max-width: 767px) {
  #Content5_0 .ant-row > .ant-col > .kh1wakf4add-editor_css {
    padding: 20px 20px 60px;
  }
}
#Content5_0 .ant-col > .content5-block-content > .kh23ooeoegt-editor_css {
  height: 20%;
  margin: 30px 0px 0px;
}
#Content5_0 .ant-col > .content5-block-content > .kh1vyb6tp6i-editor_css {
  height: 120px;
  background-color: rgb(255, 255, 255);
}
